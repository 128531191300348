import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import LanguagesView from '@/views/LanguagesView.vue'
import NewsView from '@/views/NewsView.vue'
import TokensView from '@/views/TokensView.vue'
import TshirtsView from '@/views/TshirtsView.vue'
import UsersView from '@/views/UsersView.vue'
import LoginView from '@/views/LoginView.vue'

import TokenForm from '@/views/forms/TokenForm.vue'
import NewsForm from '@/views/forms/NewsForm.vue'
import TShirtsForm from '@/views/forms/TShirtsForm.vue'
import LanguageForm from '@/views/forms/LanguageForm.vue'
import UserForm from '@/views/forms/UserForm.vue'

import UserPage from '@/views/UserView.vue'

const routes = [{
  path: '/',
  name: 'home',
  component: DashboardView,
  meta: { admin: true }
}, {
  path: '/login',
  name: 'login',
  component: LoginView
}, {
  path: '/languages',
  name: 'languages',
  component: LanguagesView,
  meta: { admin: true }
}, {
  path: '/news',
  name: 'news',
  component: NewsView,
  meta: { admin: true }
}, {
  path: '/tokens',
  name: 'tokens',
  component: TokensView,
  meta: { admin: true }
}, {
  path: '/t-shirts',
  name: 'tshirts',
  component: TshirtsView,
  meta: { admin: true }
}, {
  path: '/users',
  name: 'users',
  component: UsersView,
  meta: { admin: true }
}, {
  path: '/tokens/edit/:tid',
  name: 'tokenEdit',
  component: TokenForm,
  meta: { admin: true }
}, {
  path: '/tokens/add',
  name: 'tokenAdd',
  component: TokenForm,
  meta: { admin: true }
}, {
  path: '/news/edit/:nid',
  name: 'newsEdit',
  component: NewsForm,
  meta: { admin: true }
}, {
  path: '/news/add',
  name: 'newsAdd',
  component: NewsForm,
  meta: { admin: true }
}, {
  path: '/tshirts/edit/:tid',
  name: 'tshirtEdit',
  component: TShirtsForm,
  meta: { admin: true }
}, {
  path: '/languages/edit/:lid',
  name: 'languagesEdit',
  component: LanguageForm,
  meta: { admin: true }
}, {
  path: '/languages/add',
  name: 'languagesAdd',
  component: LanguageForm,
  meta: { admin: true }
}, {
  path: '/users/read/:uid',
  name: 'userRead',
  component: UserPage,
  meta: { admin: true }
}, {
  path: '/users/edit/:uid',
  name: 'userEdit',
  component: UserForm,
  meta: { admin: true }
}, {
  path: '/users/add',
  name: 'userAdd',
  component: UserForm,
  meta: { admin: true }
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
